// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-model-detail-js": () => import("/app/src/templates/model_detail.js" /* webpackChunkName: "component---src-templates-model-detail-js" */),
  "component---src-templates-blog-detail-js": () => import("/app/src/templates/blog_detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-generic-page-js": () => import("/app/src/templates/generic_page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-promotions-js": () => import("/app/src/templates/promotions.js" /* webpackChunkName: "component---src-templates-promotions-js" */),
  "component---src-templates-tu-calentador-ideal-js": () => import("/app/src/templates/tu-calentador-ideal.js" /* webpackChunkName: "component---src-templates-tu-calentador-ideal-js" */),
  "component---src-templates-green-foam-js": () => import("/app/src/templates/green-foam.js" /* webpackChunkName: "component---src-templates-green-foam-js" */),
  "component---src-templates-enviado-js": () => import("/app/src/templates/enviado.js" /* webpackChunkName: "component---src-templates-enviado-js" */),
  "component---src-templates-solicita-ayuda-js": () => import("/app/src/templates/solicita-ayuda.js" /* webpackChunkName: "component---src-templates-solicita-ayuda-js" */),
  "component---src-templates-contactanos-js": () => import("/app/src/templates/contactanos.js" /* webpackChunkName: "component---src-templates-contactanos-js" */),
  "component---src-templates-newsletter-js": () => import("/app/src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-nosotros-js": () => import("/app/src/templates/nosotros.js" /* webpackChunkName: "component---src-templates-nosotros-js" */),
  "component---src-templates-distribuidores-comerciales-js": () => import("/app/src/templates/distribuidores-comerciales.js" /* webpackChunkName: "component---src-templates-distribuidores-comerciales-js" */),
  "component---src-templates-compra-en-linea-js": () => import("/app/src/templates/compra-en-linea.js" /* webpackChunkName: "component---src-templates-compra-en-linea-js" */),
  "component---src-templates-comparador-js": () => import("/app/src/templates/comparador.js" /* webpackChunkName: "component---src-templates-comparador-js" */),
  "component---src-templates-service-centers-js": () => import("/app/src/templates/service_centers.js" /* webpackChunkName: "component---src-templates-service-centers-js" */),
  "component---src-templates-distribuidores-js": () => import("/app/src/templates/distribuidores.js" /* webpackChunkName: "component---src-templates-distribuidores-js" */),
  "component---src-templates-blog-js": () => import("/app/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

